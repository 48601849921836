import React from "react";
import "./footer.css";

const Footer = () => {

    return (

    
    <div id="footer">
    <div className="ending-credits">
      <div>Designed and developed by Nnamdi Ikenna-Obi. </div>
      <div>All rights reserved. ©</div>
    </div>
  </div> 
  )   
}

export default Footer;